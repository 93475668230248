import { Checkbox as BaseCheckbox, CheckboxProps, CheckboxOverrides } from 'baseui/checkbox'
import { withOverrides } from './overrides'

const globalOverrides: CheckboxOverrides = {
  Checkmark: {
    style: {
      height: '30px',
      width: '30px',
      borderRightWidth: '1px',
      borderLeftWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px'
    }
  },
  Label: {
    style: {
      fontSize: '18px',
      lineHeight: '33px'
    }
  },
  Root: {
    style: {
      marginTop: '22px'
    }
  }
}

export const Checkbox = withOverrides<CheckboxProps>(BaseCheckbox, globalOverrides)
